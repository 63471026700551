import React, { useRef } from "react";
import styled, { css } from "styled-components";
import Slider from "react-slick";
import CommonImages from '../img/common';
import MobileImages from '../img/mobile';
import { chunk } from 'lodash';
import { useFormik } from "formik";

const getResponsive = (key, value) => {
  return css`
    ${key}: calc(100vw * ${value} / 990) !important;

    @media screen and (max-width: 375px) {
      ${key}: calc(375px * ${value} / 990) !important;
    }
    @media screen and (min-width: 989px) {
      ${key}: ${value}px !important;
    }
  `;
}

const Wrap = styled.div`
  font-size: 10px;
  position: relative;
  max-width: 989px;
  min-width: 375px;
  margin: 0 auto;
`;

const Section = styled.section`
  position: relative;
`;

const Section5 = styled(Section)`
  background-color: rgb(37, 37, 37);
  ${getResponsive('height', 330)};
`;

const Form = styled.form`
  position: relative;
  height: 100%;
`;

const Anchor = styled.a`
  z-index: 10;
  position: fixed;
  ${getResponsive('top', 57)};
  ${getResponsive('right', 57)};
`;

const FloatingButtonImg = styled.img.attrs({
  src: CommonImages.buttons.btnFloating
})`
  ${getResponsive('width', 175)};
  ${getResponsive('height', 175)};
`;

const BgImage = styled.img`
  width: 100%;
`;

const TopSliderWrap = styled.div`
  position: absolute;
  width: 100%;
  ${getResponsive('bottom', 71)};
`;

const TopSliderInnerWrap = styled.div`
  position: relative;
  ${getResponsive('padding-left', 96)};
  ${getResponsive('padding-right', 96)};
`;

const TopSliderItemWrap = styled.div`
  display: inline-flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  ${getResponsive('height', 156)};
  ${getResponsive('margin-right', 24)};
`;

const TopSliderLeft = styled.img.attrs({
  src: CommonImages.buttons.btnArrowLeft
})`
  z-index: 1;
  position: absolute;
  ${getResponsive('width', 12)};
  ${getResponsive('height', 18)};
  ${getResponsive('top', 65)};
  ${getResponsive('left', 40)};
  cursor: pointer;
`

const TopSliderRight = styled.img.attrs({
  src: CommonImages.buttons.btnArrowRight
})`
  z-index: 1;
  position: absolute;
  ${getResponsive('width', 12)};
  ${getResponsive('height', 18)};
  ${getResponsive('top', 65)};
  ${getResponsive('right', 40)};
  cursor: pointer;
`

const BtnSubmit1 = styled.button.attrs({
  type: 'submit',
})`
  z-index: 1;
  position: absolute;
  ${getResponsive('width', 376)};
  ${getResponsive('height', 77)};

  ${getResponsive('bottom', 42)};
  ${getResponsive('right', 56)};

  border: none;
  background: none;
  cursor: pointer;
  padding: 0;

  img {
    width: 100%;
    height: 100%;
  }
`

const Input = styled.input`
  z-index: 1;
  position: absolute;
  ${({ top }) => getResponsive('top', top)}
  ${({ left }) => getResponsive('left', left)}
  ${getResponsive('width', 287)};
  ${getResponsive('height', 51)};
  box-sizing: border-box;
  ${getResponsive('padding-left', 10)};
  ${getResponsive('padding-right', 10)};
  border: none;
`;

const Select = styled.select`
  z-index: 1;
  position: absolute;
  ${({ top }) => getResponsive('top', top)}
  ${({ left }) => getResponsive('left', left)}
  ${getResponsive('width', 287)};
  ${getResponsive('height', 51)};
  ${getResponsive('padding-left', 10)};
  ${getResponsive('padding-right', 10)};
`;

const TextArea = styled.textarea`
  z-index: 1;
  position: absolute;
  box-sizing: border-box;
  ${getResponsive('top', 42)};
  ${getResponsive('right', 57)};
  ${getResponsive('width', 375)};
  ${getResponsive('height', 116)};
  ${getResponsive('padding', 10)};
`;

const Label = styled.label`
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${getResponsive('top', 168)};
  ${getResponsive('left', 594)};
`;

const Checkbox = styled.input.attrs({
  type: 'checkbox'
})`
  cursor: pointer;
  ${getResponsive('width', 23)};
  ${getResponsive('height', 23)};
  ${getResponsive('margin-right', 13)};
`

const PrivacyDescImg = styled.img.attrs({
  src: MobileImages.privacyDesc
})`
  ${getResponsive('width', 268)};
  ${getResponsive('height', 19)};
`;

const LabelName = styled.img.attrs({
  src: MobileImages.labelName,
})`
  z-index: 1;
  position: absolute;
  ${getResponsive('top', 55)};
  ${getResponsive('left', 57)};
  ${getResponsive('width', 50)};
  ${getResponsive('height', 27)};
`;

const LabelCare = styled.img.attrs({
  src: MobileImages.labelCare,
})`
  z-index: 1;
  position: absolute;
  ${getResponsive('top', 120)};
  ${getResponsive('left', 57)};
  ${getResponsive('width', 103)};
  ${getResponsive('height', 27)};
`;

const LabelPhone = styled.img.attrs({
  src: MobileImages.labelPhone,
})`
  z-index: 1;
  position: absolute;
  ${getResponsive('top', 185)};
  ${getResponsive('left', 57)};
  ${getResponsive('width', 76)};
  ${getResponsive('height', 27)};
`;

const LabelTime = styled.img.attrs({
  src: MobileImages.labelTime,
})`
  z-index: 1;
  position: absolute;
  ${getResponsive('top', 250)};
  ${getResponsive('left', 57)};
  ${getResponsive('width', 157)};
  ${getResponsive('height', 27)};
`;

const BottomSliderWrap = styled.div`
  position: absolute;
  width: 100%;
  ${getResponsive('height', 947)};
  ${getResponsive('bottom', 0)};
  ${getResponsive('left', 0)};
`;

const BottomSliderInnerWrap = styled.div`
  position: relative;
`;

const BottomSliderItemWrap = styled.div`
  display: inline-flex !important;
  justify-content: flex-end;
  width: 100%;
`;

const SnsButtonWrap = styled.div`
  z-index: 1;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${getResponsive('width', 651)};
  ${getResponsive('height', 615)};
  ${getResponsive('top', 607)};
  ${getResponsive('right', 170)};
`;
//
const SnsButton = styled.a`
  border: none;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
`;

const BottomForm = styled.form`
  z-index: 1;
  position: absolute;
  ${getResponsive('width', 836)};
  ${getResponsive('height', 713)};
  ${getResponsive('bottom', 46)};
  ${getResponsive('left', 83)};

  img {
    width: 100%;
    height: 100%;
  }
`;

const Section9Circle = styled.img.attrs({
  src: MobileImages.section9Circle
})`
  z-index: 1;
  position: absolute;
  ${getResponsive('left', 44)};
  ${getResponsive('top', -30)};
  ${getResponsive('width', 419)};
  ${getResponsive('height', 502)};
`;

const BottomFormInput = styled.input`
  z-index: 2;
  position: absolute;
  box-sizing: border-box;
  ${({ top }) => getResponsive('top', top)}
  ${getResponsive('width', 389)};
  ${getResponsive('height', 51)};
  ${getResponsive('right', 137)};
  ${getResponsive('padding-left', 10)};
  ${getResponsive('padding-right', 10)};
  ${getResponsive('border-width', 2)};
  border-style: solid;
  border-color: rgb(191, 191, 191);
`;

const BottomFormSelect = styled.select`
  z-index: 2;
  position: absolute;
  ${({ top }) => getResponsive('top', top)}
  ${getResponsive('width', 389)};
  ${getResponsive('height', 51)};
  ${getResponsive('right', 137)};
  ${getResponsive('padding-left', 10)};
  ${getResponsive('padding-right', 10)};
  ${getResponsive('border-width', 2)};
  border-style: solid;
  border-color: rgb(191, 191, 191);
`;

const BottomFormTextArea = styled.textarea`
  z-index: 2;
  position: absolute;
  box-sizing: border-box;
  ${getResponsive('top', 341)};
  ${getResponsive('right', 138)};
  ${getResponsive('width', 574)};
  ${getResponsive('height', 153)};
  ${getResponsive('padding', 10)};
  border-style: solid;
  border-color: rgb(191, 191, 191);
`;

const BottomLabel = styled.label`
  z-index: 2;
  position: absolute;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${getResponsive('top', 508)};
  ${getResponsive('left', 259)};
`;

const BottomCheckbox = styled.input.attrs({
  type: 'checkbox'
})`
  cursor: pointer;
  ${getResponsive('width', 23)};
  ${getResponsive('height', 23)};
  ${getResponsive('margin-right', 14)};
`

const BottomPrivacyDescImg = styled.img.attrs({
  src: MobileImages.privacyDescBottom
})`
  ${getResponsive('width', 268)};
  ${getResponsive('height', 19)};
`;

const LabelNameBottom = styled.img.attrs({
  src: MobileImages.labelNameBottom,
})`
  z-index: 1;
  position: absolute;
  ${getResponsive('top', 80)};
  ${getResponsive('left', 124)};
  ${getResponsive('width', 50)};
  ${getResponsive('height', 27)};
`;

const LabelPhoneBottom = styled.img.attrs({
  src: MobileImages.labelPhoneBottom,
})`
  z-index: 1;
  position: absolute;
  ${getResponsive('top', 145)};
  ${getResponsive('left', 124)};
  ${getResponsive('width', 76)};
  ${getResponsive('height', 27)};
`;

const LabelCareBottom = styled.img.attrs({
  src: MobileImages.labelCareBottom,
})`
  z-index: 1;
  position: absolute;
  ${getResponsive('top', 210)};
  ${getResponsive('left', 124)};
  ${getResponsive('width', 103)};
  ${getResponsive('height', 27)};
`;

const LabelTimeBottom = styled.img.attrs({
  src: MobileImages.labelTimeBottom,
})`
  z-index: 1;
  position: absolute;
  ${getResponsive('top', 275)};
  ${getResponsive('left', 124)};
  ${getResponsive('width', 157)};
  ${getResponsive('height', 27)};
`;

const BottomFormSubmit = styled.button.attrs({
  type: 'submit'
})`
  z-index: 2;
  position: absolute;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  ${getResponsive('bottom', 81)};
  ${getResponsive('left', 190)};
  ${getResponsive('width', 443)};
  ${getResponsive('height', 73)};

  img {
    width: 100%;
    height: 100%;
  }
`;

const FooterButtonImg1 = styled.img.attrs({
  src: CommonImages.section10Buttons.section10Btn1
})`
  z-index: 1;
  position: absolute;
  cursor: pointer;
  ${getResponsive('top', 42)};
  ${getResponsive('left', 322)};
  ${getResponsive('width', 173)};
  ${getResponsive('height', 43)};
`;

const FooterButtonImg2 = styled.img.attrs({
  src: CommonImages.section10Buttons.section10Btn2
})`
  z-index: 1;
  position: absolute;
  ${getResponsive('top', 42)};
  ${getResponsive('left', 508)};
  ${getResponsive('width', 173)};
  ${getResponsive('height', 43)};
`;

const FooterButton3 = styled.div`
  z-index: 1;
  position: absolute;
  cursor: pointer;
  background: white;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 7px;
  font-family: sans-serif;
  font-weight: 700;
  color: rgb(101 101 101 / 100%);
  border-radius: 4px;
  ${getResponsive('top', 42)};
  ${getResponsive('left', 693)};
  ${getResponsive('width', 173)};
  ${getResponsive('height', 43)};
`;

const validate = ({ name, phone, time, care, privacy }) => {
  if (privacy === false) {
    alert('개인정보 수집 및 이용에 동의해주세요.');
    return false;
  }
  if (name === '') {
    alert('성함을 입력해주세요.');
    return false;
  }
  if (phone === '') {
    alert('연락처를 입력해주세요.');
    return false;
  }
  if (care === '') {
    alert('치료를 선택해주세요.')
    return false;
  }
  if (time === '') {
    alert('상담가능시간을 선택해주세요.')
    return false;
  }
  return true;
};

const sendEmail = (values, subject) => {
  setTimeout(() => {
    alert('이벤트 신청이 완료되었습니다. 감사합니다.');
  }, 0);

  const { name, phone, time, care, consult } = values;


  const sendingPromise1 = Email.send({
    Host: 'email-smtp.ap-northeast-2.amazonaws.com',
    Username: 'AKIA5SPY2KVLAX6IAZKI',
    Password: 'BD0Qxjwjiuid2ElNhXJrNNkuswGnIdBT70k1daT9tMr8',
    To: 'gongju3030@naver.com',
    // To: 'yangtopia@gmail.com',
    From: 'no-reply@seoul-better.com',
    Subject: subject,
    Body:
      `<html lang="ko">
                    <table style="border: 1px solid #000000; border-collapse: collapse;">
                        <tbody>
                            <tr>
                                <th style="padding: 10px; border: 1px solid #000000">이름</th>
                                <td style="padding: 10px; border: 1px solid #000000">${name}</td>
                            </tr>
                            <tr>
                                <th style="padding: 10px; border: 1px solid #000000">연락처</th>
                                <td style="padding: 10px; border: 1px solid #000000">${phone}</td>
                            </tr>
                            <tr>
                                <th style="padding: 10px; border: 1px solid #000000">치료선택</th>
                                <td style="padding: 10px; border: 1px solid #000000">${care}</td>
                            </tr>
                            <tr>
                                <th style="padding: 10px; border: 1px solid #000000">상담가능시간</th>
                                <td style="padding: 10px; border: 1px solid #000000">${time}</td>
                            </tr>
                            <tr>
                                <th style="padding: 10px; border: 1px solid #000000">문의내용</th>
                                <td style="padding: 10px; border: 1px solid #000000">${consult}</td>
                            </tr>
                        </tbody>
                    </table>
                </html>`,
  });

  const sendingPromise2 = Email.send({
    Host: 'email-smtp.ap-northeast-2.amazonaws.com',
    Username: 'AKIA5SPY2KVLAX6IAZKI',
    Password: 'BD0Qxjwjiuid2ElNhXJrNNkuswGnIdBT70k1daT9tMr8',
    To: 'levle_corp@naver.com',
    // To: 'sodacrush@naver.com',
    From: 'no-reply@seoul-better.com',
    Subject: subject,
    Body:
      `<html lang="ko">
                    <table style="border: 1px solid #000000; border-collapse: collapse;">
                        <tbody>
                            <tr>
                                <th style="padding: 10px; border: 1px solid #000000">이름</th>
                                <td style="padding: 10px; border: 1px solid #000000">${name}</td>
                            </tr>
                            <tr>
                                <th style="padding: 10px; border: 1px solid #000000">연락처</th>
                                <td style="padding: 10px; border: 1px solid #000000">${phone}</td>
                            </tr>
                            <tr>
                                <th style="padding: 10px; border: 1px solid #000000">치료선택</th>
                                <td style="padding: 10px; border: 1px solid #000000">${care}</td>
                            </tr>
                            <tr>
                                <th style="padding: 10px; border: 1px solid #000000">상담가능시간</th>
                                <td style="padding: 10px; border: 1px solid #000000">${time}</td>
                            </tr>
                            <tr>
                                <th style="padding: 10px; border: 1px solid #000000">문의내용</th>
                                <td style="padding: 10px; border: 1px solid #000000">${consult}</td>
                            </tr>
                        </tbody>
                    </table>
                </html>`,
  });

  return Promise.all([sendingPromise1, sendingPromise2]);
}

const MobileContainer = () => {
  const topSlider = useRef(null);
  const bottomSlider = useRef(null);

  const topSliderSetting = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 1500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    draggable: false,
    touchMove: false
  };

  const bottomSliderSetting = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    responsiveWidth: true,
    draggable: false,
    touchMove: false
  };

  const handleSlidePrev = () => {
    topSlider.current.slickPrev();
  }

  const handleSlideNext = () => {
    topSlider.current.slickNext();
  }

  const topForm = useFormik({
    initialValues: {
      name: '',
      phone: '',
      care: '',
      time: '',
      consult: '',
      privacy: true
    },
    onSubmit: (values) => {
      const isValidated = validate(values);
      if (isValidated) {
        sendEmail(values, '서울나은치과 랜딩_DB-1');
      }
    }
  })

  const bottomForm = useFormik({
    initialValues: {
      name: '',
      phone: '',
      care: '',
      time: '',
      consult: '',
      privacy: true
    },
    onSubmit: async (values) => {
      const isValidated = validate(values);
      if (isValidated) {
        await sendEmail(values, '서울나은치과 랜딩_DB-2');
      }
    }
  })

  return (
    <Wrap>
      <Anchor href="#inquiry">
        <FloatingButtonImg />
      </Anchor>
      <Section>
        <BgImage src={MobileImages.section1} />
        <TopSliderWrap>
          <TopSliderInnerWrap>
            <TopSliderLeft onClick={() => handleSlidePrev()} />
            <Slider ref={topSlider} {...topSliderSetting}>
              {chunk(CommonImages.logos, 2).map(([Logo1, Logo2]) => (
                <TopSliderItemWrap key={Logo1}>
                  <Logo1 />
                  <Logo2 />
                </TopSliderItemWrap>
              ))}
            </Slider>
            <TopSliderRight onClick={() => handleSlideNext()} />
          </TopSliderInnerWrap>
        </TopSliderWrap>
      </Section>
      <Section>
        <BgImage src={MobileImages.section2} />
      </Section>
      {/* <Section>
        <BgImage src={MobileImages.section3} />
      </Section> */}
      <Section>
        <BgImage src={MobileImages.section4} />
      </Section>
      {/* <Section5>
        <Form onSubmit={topForm.handleSubmit}>
          <LabelName />
          <Input
            name="name"
            onChange={topForm.handleChange}
            top={42}
            left={243}
            value={topForm.values.name}
            placeholder="이름"
          />
          <LabelCare />
          <Select
            name="care"
            value={topForm.values.care}
            onChange={topForm.handleChange}
            top={107}
            left={243}
          >
            <option value="">치료선택</option>
            <option value="임플란트">임플란트</option>
            <option value="치아교정">치아교정</option>
            <option value="사랑니발치">사랑니발치</option>
            <option value="일반진료 (검진,충치,스케일링 등)">일반진료 (검진,충치,스케일링 등)</option>
          </Select>
          <LabelPhone />
          <Input
            name="phone"
            value={topForm.values.phone}
            onChange={topForm.handleChange}
            top={172}
            left={243}
            placeholder="연락처"
          />
          <LabelTime />
          <Select
            name="time"
            value={topForm.values.time}
            onChange={topForm.handleChange}
            top={237}
            left={243}
          >
            <option value="">상담 희망시간</option>
            <option value="오전">오전</option>
            <option value="오후">오후</option>
            <option value="상시가능">상시가능</option>
          </Select>
          <TextArea
            name="consult"
            placeholder="문의내용"
            value={topForm.values.consult}
            onChange={topForm.handleChange}
          />
          <Label>
            <Checkbox
              name="privacy"
              checked={topForm.values.privacy}
              onChange={topForm.handleChange}
            />
            <PrivacyDescImg />
          </Label>
          <BtnSubmit1>
            <img src={MobileImages.btnSubmit1} />
          </BtnSubmit1>
        </Form>
      </Section5> */}
      <Section>
        <BgImage src={MobileImages.section6} />
      </Section>
      <Section>
        <BgImage src={MobileImages.section7_1} />
      </Section>
      <Section>
        <BgImage src={MobileImages.section7_2} />
        <BottomSliderWrap>
          <BottomSliderInnerWrap>
            <Slider ref={bottomSlider} {...bottomSliderSetting}>
              {MobileImages.section7SliderItems.map(Item => (
                <BottomSliderItemWrap key={Item}>
                  <Item />
                </BottomSliderItemWrap>
              ))}
            </Slider>
          </BottomSliderInnerWrap>
        </BottomSliderWrap>
      </Section>
      {/* <Section>
        <BgImage src={MobileImages.section7_3} />
      </Section> */}
      <Section id="sns">
        <BgImage src={MobileImages.section8} />
        <SnsButtonWrap>
          {CommonImages.section8Buttons.map((item) => (
            <SnsButton
              key={item.link}
              href={item.link}
            >
              <img src={item.img} />
            </SnsButton>
          ))}
        </SnsButtonWrap>
      </Section>
      <Section id="inquiry">
        <Section9Circle />
        <BgImage src={MobileImages.section9} />
        {/* <BottomForm onSubmit={bottomForm.handleSubmit}>
          <img src={MobileImages.section9Form} />
          <LabelNameBottom />
          <BottomFormInput
            top={67}
            name="name"
            value={bottomForm.values.name}
            onChange={bottomForm.handleChange}
            placeholder="이름"
          />
          <LabelPhoneBottom />
          <BottomFormInput
            name="phone"
            value={bottomForm.values.phone}
            onChange={bottomForm.handleChange}
            top={132}
            placeholder="연락처"
          />
          <LabelCareBottom />
          <BottomFormSelect
            name="care"
            top={197}
            value={bottomForm.values.care}
            onChange={bottomForm.handleChange}
          >
            <option value="">치료선택</option>
            <option value="임플란트">임플란트</option>
            <option value="치아교정">치아교정</option>
            <option value="사랑니발치">사랑니발치</option>
            <option value="일반진료 (검진,충치,스케일링 등)">일반진료 (검진,충치,스케일링 등)</option>
          </BottomFormSelect>
          <LabelTimeBottom />
          <BottomFormSelect
            top={262}
            name="time"
            value={bottomForm.values.time}
            onChange={bottomForm.handleChange}
          >
            <option value="">상담 희망시간</option>
            <option value="오전">오전</option>
            <option value="오후">오후</option>
            <option value="상시가능">상시가능</option>
          </BottomFormSelect>
          <BottomFormTextArea
            name="consult"
            value={bottomForm.values.consult}
            onChange={bottomForm.handleChange}
            placeholder="문의내용"
          />
          <BottomLabel>
            <BottomCheckbox
              name="privacy"
              checked={bottomForm.values.privacy}
              onChange={bottomForm.handleChange}
            />
            <BottomPrivacyDescImg />
          </BottomLabel>
          <BottomFormSubmit>
            <img src={MobileImages.btnSubmit2} />
          </BottomFormSubmit>
        </BottomForm> */}
      </Section>
      <Section>
        <BgImage src={MobileImages.section10} />
        <FooterButtonImg1 onClick={() => window.open('/privacy.html')} />
        <FooterButtonImg2 />
        <FooterButton3 onClick={() => window.open(MobileImages.feeSchedule)}><span style={{
          display: 'inline-block',
          lineHeight: '1em',
          top: '1.2px',
          position: 'relative',
        }}>비급여수가표</span></FooterButton3>
      </Section>
    </Wrap>
  );
}

export default MobileContainer;
